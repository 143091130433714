import { useEffect, useLayoutEffect, useRef, useState } from "react";
import "../../scss/global.scss";
import "./home.scss";
import Intro from "../../animation/Intro";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { MdOutlineReplay } from "react-icons/md";

const Home: React.FC = () => {
    const [isToggled, setIsToggled] = useState(false);
    const timelines = useRef<gsap.core.Timeline[]>([]);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    useGSAP(() => {
        const pins = document.querySelectorAll(".pin");
        const replayBtn = document.querySelector(".replay-btn");
        const isMobile = window.innerWidth <= 768;
    
        timelines.current = Array.from(pins).map((pin) =>
            gsap
                .timeline({ paused: true })
                .fromTo(
                    pin.querySelector(".asterisk"),
                    { scale: 0 },
                    { scale: 1, stagger: 0.2 }
                )
                .from(
                    pin.querySelector(".box-line"),
                    { height: 0, stagger: 0.2  }
                )
                .fromTo(
                    pin.querySelector(".box"),
                    { width: 0 },
                    { width: "100%", stagger: 0.2 }
                )
                .fromTo(
                    pin.querySelector(".box-copy"),
                    { opacity: 0 },
                    { opacity: 1, stagger: 0.2 }
                )
        );
           
        if (replayBtn) {
            timelines.current[0]
                .fromTo(
                    replayBtn,
                    { opacity: 0, scale: 0 },
                    { opacity: 1, scale: 1, duration: 0.5, ease: "back.out(1.7)" },
                    "-=0.5"
                );
        }
    }, []);
    




    const replayVideo = () => {
        timelines.current.forEach((tl) => tl.reverse());
        setTimeout(() => {
            if (videoRef.current) {
                videoRef.current.currentTime = 0;
                videoRef.current.play();
            }
        }, 2000);
        setTimeout(() => {
            timelines.current.forEach((tl) => tl.play());
        }, 16500);
    };

    const toggleAnimations = () => {
        if (isToggled) {
            timelines.current.forEach((tl) => tl.reverse());
        } else {
            setTimeout(() => {
                timelines.current.forEach((tl) => tl.play());
            }, 14500);
        }
        setIsToggled(!isToggled);
    };

    const videoRef = useRef<HTMLVideoElement | null>(null);

    useEffect(() => {
        const video = videoRef.current;

        if (!video) return;

        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.intersectionRatio >= 0.5) {
                    video.play();
                    toggleAnimations();
                    observer.disconnect();
                }
            },
            {
                threshold: [0.5],
            }
        );

        observer.observe(video);

        return () => {
            observer.disconnect();
        };
    }, []);

    return (
        <>
            <Intro />
            <div className="film">
                <video playsInline muted ref={videoRef}>
                    <source src="../videos/1.mp4" type="video/mp4" />
                </video>
                <div className={"pin" + ' ' + "redbox"}>
                    <div className="box">
                        <p className="box-copy">RENTAL APARTMENTS</p>
                    </div>
                </div>
                <div className={"pin" + ' ' + "redbox"}>
                    <div className="box">
                        <p className="box-copy">RENTAL APARTMENTS</p>
                    </div>
                </div>
                <div className={"pin" + ' ' + "redbox"}>
                    <div className="box">
                        <p className="box-copy">PUBLIC PARK</p>
                    </div>
                </div>
                <div className={"pin" + ' ' + "redbox"}>
                    <div className="box">
                        <p className="box-copy">WORKSPACE</p>
                    </div>
                </div>
                <div className="pin">
                    <div className={"box" + ' ' + "redbox"}>
                        <p className="box-copy">CR&Egrave;CHE</p>
                    </div>
                </div>
                <div className="pin">
                    <div className="box">
                        <p className="box-copy">MY North<br />25,809 sq ft</p>
                    </div>
                    <div className="box-line"></div>
                    <div
                        className="asterisk"
                        style={{ backgroundImage: "url(../ui/asterisk.svg" }}
                    ></div>
                </div>
                <div className="pin">
                    <div className="box">
                        <p className="box-copy">MY Central<br />65,449 sq ft</p>
                    </div>
                    <div className="box-line"></div>
                    <div
                        className="asterisk"
                        style={{ backgroundImage: "url(../ui/asterisk.svg" }}
                    ></div>
                </div>
                <div className="pin">
                    <div className="box">
                        <p className="box-copy">MY South<br />21,574 sq ft</p>
                    </div>
                    <div className="box-line"></div>
                    <div
                        className="asterisk"
                        style={{ backgroundImage: "url(../ui/asterisk.svg" }}
                    ></div>
                </div>
                <div onClick={replayVideo} className="replay-btn">
                    <p>Replay Video</p>
                    <MdOutlineReplay color="#174B32" width={"100%"} />
                </div>
            </div>
        </>
    );
};

export default Home;
