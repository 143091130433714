import "./schemeintro.scss";

const Video = () => {
    return (
        <div className="contain-wrapper">
            <div className="video contain-two">
                <video controls>
                    <source
                        src="https://player.vimeo.com/progressive_redirect/playback/1045730119/rendition/1080p/file.mp4?loc=external&signature=6674c03cd7c60ef1daec433ab3e170c45c68acfc7f3cd88a8771b9f68ca2abdc"
                        type="video/mp4"
                    />
                </video>
            </div>
        </div>
    );
};

export default Video;
